import React, { useContext, FunctionComponent, Fragment, useMemo } from "react";
import { SolarEfficiencyGauge } from "./SolarEfficiencyGauge";
import { PanelInfo } from "./GenericInfo/PanelInfo";
import DateTimeBox from "./DateTimeBox/DateTimeBox";
import { DisplayBoardContext } from "../../Context";
import WeatherBox from "./WeatherBox/WeatherBox";
import {
  SolarEfficiencyGaugeContainer,
  SideBarContentContainer,
  WeatherDateContainer,
  BottomLogoContainer,
  SideBarContainer,
  TopLogoContainer,
  BottomLogo,
  TopLogo,
  TopLogoDefault,
} from "./SideBar.styles";
import { SolarVsGridBars } from "./SolarVsGridBars/SolarVsGridBars";
import { useTimeSpanData } from "../../hooks/Context Hooks/data/useData";
import { SimpleLineGraph } from "./SimpleLineGraph/SimpleLineGraph";
import {
  DISCOVERY_CAMPS_IDS,
  DISCOVERY_CAMPS_ORG_IDS,
} from "../../temp/DiscoveryCamps";

/*
  Container for the side bar that displays the logo, weather/time/date, and current total system output 
*/
// const SideBarContainer = ({ id, data, weather, logo, footer }) => {
const SideBar: FunctionComponent = () => {
  const {
    config: { logo: configLogo, backgroundImage, installerLogo },
    id,
    data,
    themeName,
    hasWeather,
    type,
  } = useContext(DisplayBoardContext);

  const hasWeatherData = useMemo(() => {
    if (
      data?.energyDetails?.weather &&
      typeof data.energyDetails.weather === "object"
    ) {
      return Object.keys(data.energyDetails.weather).length > 0;
    }

    return false;
  }, [data]);

  const todayData = useTimeSpanData("daily")?.data;
  const thisDayLastWeek = data?.thisDayLastWeek?.data;
  const logo = configLogo && configLogo.split("-").join("");

  const peakCapacity = data?.energyDetails?.peakSolarCapacity || null;

  return (
    <SideBarContainer themeName={themeName} backgroundImage={backgroundImage}>
      <TopLogoContainer>
        {logo && (
          <TopLogo
            alt="logo"
            src={`logos/${logo ? logo : themeName}.png`}
            disableFilter={logo.split(".")?.[1] === "dark"}
          />
        )}

        {DISCOVERY_CAMPS_IDS.includes(parseInt(id, 10)) && (
          <div style={{ marginTop: "2rem" }}>
            <img
              style={{ height: "auto", width: "100%" }}
              src="logos/ymcaHeading.png"
            />
          </div>
        )}
      </TopLogoContainer>
      <SideBarContentContainer>
        {hasWeatherData && (
          <WeatherDateContainer>
            <WeatherBox data={data} />
            <DateTimeBox />
          </WeatherDateContainer>
        )}
        {type === "sites" ? (
          <Fragment>
            {/* dont show gauge for non-solar sites */}
            {peakCapacity !== null && !DISCOVERY_CAMPS_IDS.includes(parseInt(id, 10)) && (
              <SolarEfficiencyGaugeContainer>
                <SolarEfficiencyGauge id={id} />
              </SolarEfficiencyGaugeContainer>
            )}
          </Fragment>
        ) : type === "schemas" ? (
          <Fragment>
            <PanelInfo />
            <SolarVsGridBars />
            {/* <Co2Info /> */}
            {/* <SiteRankings /> */}
          </Fragment>
        ) : (
          type === "organisations" &&
          !DISCOVERY_CAMPS_ORG_IDS.includes(parseInt(id, 10)) && (
            <SimpleLineGraph data={todayData} lastWeekData={thisDayLastWeek} />
          )
        )}
        <BottomLogoContainer>
          {installerLogo && (
            <BottomLogo
              src={`logos/${installerLogo}.png`}
              alt="installer-logo"
            />
          )}
          {/* doesnt't show the bottom theme logo if theme logo is used as primary */}
          {logo &&
          !logo.includes(themeName) && // for variants like .dark
            !(logo === "solarschools" && themeName === "solarschools") && (
              <BottomLogo
                src={`theme-images/${themeName}/logo.png`}
                alt="theme-logo"
              />
            )}
        </BottomLogoContainer>
      </SideBarContentContainer>
    </SideBarContainer>
  );
};

export default SideBar;
